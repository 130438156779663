@font-face {
    font-family: "Font Awesome 6 Pro Duetone";
    font-weight: normal;
    font-style: normal;

    src: url("../fonts/fa-duotone-900.woff2") format("woff2"),
        url("../fonts/fa-duotone-900.woff") format("woff"),
        url("../fonts/fa-duotone-900.ttf") format("truetype");
}

/*
 * Carousel CSS
 */
.carousel {
    width: 100%;
    position: relative;
    display: flex;
}
.carousel .carousel-outer {
    height: 100%;
}

.carousel .carousel-inner .slidenav {
    position: absolute;
    bottom: 40px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.carousel .carousel-inner .slidenav li {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    padding: 0;
    cursor: pointer;
}

.carousel .carousel-inner button.slide-button {
    font-size: 0;
    display: block;
    padding: 5px;
    cursor: pointer;
    border: 0;
    background: transparent;
    border-radius: 50% 50%;
}

.carousel .carousel-inner button.slide-button:before {
    font-family: "Font Awesome 6 Pro Duetone";
    font-size: 20px;
    content: "\f111";
    text-align: center;
    color: #7f7f7f;
    opacity: 1;
    position: absolute;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.carousel .carousel-inner button.slide-button:after {
    font-family: "Font Awesome 6 Pro Duetone";
    font-size: 20px;
    content: "\10f111";
    text-align: center;
    color: #ffffff;
    opacity: 1;
    position: absolute;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.carousel .carousel-inner button.slide-button.current:after {
    color: #333333;
}

.carousel .carousel-inner button.slide-button:hover:after {
    color: #333333;
}

.carousel .carousel-inner .carousel-item {
    display: none;
    max-height: 480px;
    max-width: 855px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.carousel .carousel-inner .carousel-item.active {
    display: block;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

/* More like bootstrap, less accessible */
.carousel .carousel-inner .carousel-items.focus {
    outline: solid 3px #005a9c;
}

.carousel .carousel-inner .carousel-item .carousel-image img {
    height: 100%;
    width: 100%;
}

.carousel .carousel-inner .carousel-item .carousel-caption a {
    text-decoration: underline;
}

.carousel .carousel-inner .carousel-item .carousel-caption h3,
.carousel .carousel-inner .carousel-item .carousel-caption span.contrast {
    display: inline-block;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.65);
    padding: 0 5px;
    border-radius: 5px;
    border: 2px solid transparent;
    margin: 5px 0 0 0;
}

.carousel .carousel-inner .carousel-item .carousel-caption h3 a {
    color: #ffffff;
    font-weight: 600;
}

.carousel .carousel-inner .carousel-item .carousel-caption h3:hover,
.carousel .carousel-inner .carousel-item .carousel-caption span.contrast:hover,
.carousel .carousel-inner .carousel-item .carousel-caption h3:focus,
.carousel .carousel-inner .carousel-item .carousel-caption span.contrast:focus {
    background-color: rgba(0, 0, 0, 1);
}

.carousel .carousel-inner .carousel-item .carousel-caption a:focus {
    background-color: rgba(0, 0, 0, 1);
    border-color: #fff;
    margin: 0;
}

.carousel .carousel-inner .carousel-item .carousel-caption p {
    font-size: 16px;
    line-height: 140%;
    color: #414042;
    margin-bottom: 0;
}

.carousel .carousel-inner .carousel-item .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 0;
    left: 15%;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

/* Shared CSS for Pause, Next and Previous Slide Controls */
.carousel .controls button {
    padding: 0;
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.carousel:hover .controls button:hover,
.carousel .controls button:focus {
    opacity: 1;
}

.carousel .controls button i {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    color: rgba(0, 0, 0, 0);
    text-shadow: 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
        -1px -1px 0 #ffffff, 1px 0px 0 #ffffff, 0px 1px 0 #ffffff,
        -1px 0px 0 #ffffff, 0px -1px 0 #ffffff,
        1px 1px 1px rgba(206, 206, 206, 0);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* Next and Previous Slide Controls */
.carousel .fa-stack {
    height: 40px;
    width: 40px;
    line-height: 40px;
}

.carousel .fa-stack i {
    line-height: 40px;
}

.carousel .controls button.previous,
.carousel .controls button.next {
    height: 100%;
    width: 40px;
    opacity: 0;
}

.carousel .controls button.previous {
    left: 0;
}

.carousel .controls button.next {
    right: 0;
}

.carousel .controls button i.previous,
.carousel .controls button i.next {
    height: 100%;
    width: 40px;
    opacity: 0;
}

.carousel:hover .controls button.previous,
.carousel:hover .controls button.next {
    opacity: 0.6;
}

.carousel:hover .controls button.previous:hover,
.carousel:hover .controls button.next:hover,
.carousel .controls button.previous:focus,
.carousel .controls button.next:focus {
    opacity: 1;
}

.carousel:hover .controls button.previous i,
.carousel:hover .controls button.next i,
.carousel .controls button.previous:focus i,
.carousel .controls button.next:focus i {
    color: rgba(0, 0, 0, 1);
    text-shadow: 1px 1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
        -1px -1px 0 #ffffff, 1px 0px 0 #ffffff, 0px 1px 0 #ffffff,
        -1px 0px 0 #ffffff, 0px -1px 0 #ffffff,
        1px 1px 1px rgba(206, 206, 206, 0);
}

/* Play/pause Control */
.carousel .controls button.rotation {
    left: 45px;
    top: 5px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 20px;
}

.carousel .controls button.rotation:hover,
.carousel .controls button.rotation:focus {
    background-color: rgba(255, 255, 255, 1);
}

.carousel .controls button.rotation.play i.pause,
.carousel .controls button.rotation.pause i.play {
    color: rgba(0, 0, 0, 0);
    display: none;
}

.carousel .controls button.rotation.pause i.pause,
.carousel .controls button.rotation.play i.play {
    color: rgba(0, 0, 0, 1);
}

a.carousel-button:hover,
a.carousel-button:focus {
    background-color: #000000;
    border: 1px #000000 solid;
    color: #ffffff;
    text-decoration: none !important;
}

a.carousel-button:active {
    background-color: #6d6e71;
    border: 1px #6d6e71 solid;
    color: #ffffff;
}

a.carousel-button:visited,
a.carousel-button {
    background-color: #e11156;
    color: #ffffff;
    border: 1px #e11156 solid;
    cursor: pointer;
    padding: 5px 25px;
    text-align: center;
    border-radius: 18px;
    font-weight: 400;
}

.events-action a.carousel-button {
    margin-right: 15px;
    display: block;
    width: 145px;
}

.carousel-caption-mobile > header {
    text-align: left;
}
.carousel-caption-mobile p {
    color:#098484;
}
@media only screen and (max-device-width : 667px) {
    .events-carousel {
        margin-top:50px;
    }
    .carousel-caption-mobile > header h1 {
        font-size: 250%;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
